@import url("./utils/fonts/noto-san-jp/css/font-notosansjp.css");

html,
body {
  height: auto;
  overflow: hidden;
  background: #d2d6de;
  font-family: "Noto Sans JP", sans-serif;
}
body,
#root,
.wrapper {
  min-height: 100vh !important;
  bottom: 0;
}


.react-datepicker button{
  min-width: unset !important;
}

.skin-blue .sidebar-menu > li > a {
  border-left: 3px solid transparent;
}
.skin-blue .sidebar-menu > li.active > a {
  border-left-color: #3c8dbc;
}
.content-header {
  position: relative;
  padding: 0px 15px 15px 15px;
}
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default.active {
  background-color: #98bbf5;
  border-color: #86aef0;
}
ul > li {
  cursor: pointer;
}
.box {
  margin-bottom: 0px;
}

.react-datepicker {
  font-size: 1em;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}
.p-0 {
  padding: 0 !important;
}

.hidden {
  display: none !important;
}
.f-r {
  float: right;
}
.mr-3 {
  margin-right: 10px;
}

//Modal
.modal-header {
  border-bottom: 1px solid #ddd;
}
.modal-footer {
  border-top: 1px solid #ddd;
}
.ReactModal__Content {
  padding: 0px !important;
}
.fa-sort-desc,
.fa-unsorted,
.fa-sort-asc {
  padding-left: 10px;
  color: #3c8dbc;
}
.font-bold {
  font-weight: bold;
}
.ReactModal__Overlay {
  z-index: 9000;
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  justify-content: center;
}
button[disabled] {
  cursor: not-allowed;
}
//Color
.color-theme {
  color: #3c8dbc;
}
.color-red {
  color: red;
}
//cursor
.cursor-p {
  cursor: pointer;
}
//button
.btn {
  min-width: 90px;
}
//Error
.error {
  input {
    border-color: red;
  }
}

//Text
.text-c {
  text-align: center;
}
//display
.display-n {
  display: none;
}
//padding-s
.pt-12 {
  padding-top: 12px;
}
.message {
  white-space: pre-wrap;
}

.btn-primary {
  background-color: #377fa8;
}

.sidebar-mini.sidebar-collapse .main-footer {
  margin-left: 50px;
  z-index: 840;
}

.styled-scroll {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    transition: 2s;
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}
