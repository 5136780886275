.login-box {
  margin: 20vh auto;
}
.login-page-wrapper {
  display: flex;
  height: calc(100vh - 50px);
  justify-content: space-between;
  .frame-none {
    margin-right: -50%;
  }
  .frame-visible {
    margin-right: 0%;
    float: right;
    height: 100%;
  }
  iframe {
    width: 50%;
    transition: 1s;
    border: none;
  }
  .login-page {
    transition: 1s;
    overflow: auto;
    width: 50%;
    background: #fff;
    .row > button {
      margin-top: 15px;
    }
    .switch-lang {
      padding: 0;
    }
  }
  .full-width {
    width: 100%;
  }
  .login-box-body {
    background: #D9D9D9;
  }
}
.sidebar-mini.sidebar-collapse {
  #root {
    .login-page {
      .main-footer {
        margin-left: 0 !important;
        z-index: 840;
      }
    }
  }
}

.lang-switch-footer {
  width: 100%;
  margin-left: 0px;
  position: absolute;
  bottom: 0;
  padding-left: 12px;
  padding-right: 12px;
}
